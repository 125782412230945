import { useEffect, useState } from 'react'

import { HubtypeService } from '../../../../../repository/hubtype/hubtype-service'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { TextSmall, TextSmallBold } from '../../../base'
import { StyledActiveUserInfoContainer } from './session-styles'

export const ActiveUserInfo = ({ activeUserId, color }: any) => {
  const authToken = useFlowBuilderSelector(ctx => ctx.state.authToken)
  const [userInfoText, setUserInfoText] = useState<string>('')

  const getUserInfoText = async (userId: string) => {
    const hubtypeUser = await HubtypeService.getUserData(authToken, userId)
    if (!hubtypeUser) return userId
    const hasCompleteName = hubtypeUser.firstName && hubtypeUser.lastName
    if (hasCompleteName) {
      return `${hubtypeUser.firstName} ${hubtypeUser.lastName}`
    }
    return hubtypeUser.email
  }

  useEffect(() => {
    const [userId] = activeUserId.split(':')
    getUserInfoText(userId).then(text => setUserInfoText(text))
  }, [])

  return (
    <StyledActiveUserInfoContainer color={color}>
      <TextSmallBold>Currently active user</TextSmallBold>
      {userInfoText && <TextSmall>{userInfoText}</TextSmall>}
    </StyledActiveUserInfoContainer>
  )
}
