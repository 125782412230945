import { Key, useRef } from 'react'
import { useGridList } from 'react-aria'
import { ListProps, OverlayTriggerState, useListState } from 'react-stately'

import { Popover } from '../../common/dropdown/popover'
import { DropdownData, DropdownProps } from '../../common/dropdown/types'
import { HtButton } from '../../ht-button/ht-button'
import { Size } from '../../variants'
import { ListItem } from './list-item'
import {
  ListFooter,
  ListHeader,
  StyledCheckboxList,
} from './multiselect-styles'

interface CheckboxListProps<T extends DropdownData>
  extends ListProps<T>,
    DropdownProps {
  overlayState: OverlayTriggerState
  applyButtonText: string
  triggerRef: React.RefObject<HTMLButtonElement>
  onApply: (keys: Key[]) => void
}

export const CheckboxList = <T extends DropdownData>({
  overlayState,
  ...props
}: CheckboxListProps<T>): JSX.Element => {
  const state = useListState(props)
  const ref = useRef<HTMLUListElement>(null)
  const { gridProps } = useGridList(props, state, ref)

  const onApply = () => {
    props.onApply(Array.from(state.selectionManager.selectedKeys))
    overlayState.close()
  }

  return (
    <Popover
      state={overlayState}
      triggerRef={props.triggerRef}
      listProps={props.listProps}
    >
      <ListHeader>
        <button
          onClick={() =>
            !state.selectionManager.isSelectAll &&
            state.selectionManager.selectAll()
          }
        >
          Select all
        </button>
        <button onClick={() => state.selectionManager.clearSelection()}>
          Clear all
        </button>
      </ListHeader>
      <StyledCheckboxList {...gridProps} ref={ref}>
        {[...state.collection].map(
          item =>
            state.selectionManager.selectedKeys.has(item.key) && (
              <ListItem key={item.key} item={item} state={state} />
            )
        )}
        {!state.selectionManager.isSelectAll &&
          state.selectionManager.selectedKeys.size !== 0 && <hr />}
        {[...state.collection].map(
          item =>
            !state.selectionManager.selectedKeys.has(item.key) && (
              <ListItem key={item.key} item={item} state={state} />
            )
        )}
      </StyledCheckboxList>
      <ListFooter>
        <HtButton size={Size.SMALL} onClick={onApply}>
          {props.applyButtonText}
        </HtButton>
      </ListFooter>
    </Popover>
  )
}
