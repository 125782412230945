import { HtQueueLocale } from '../cms/hubtype/common'
import {
  ContentFieldsBase,
  ContentType,
  NonMessageContentType,
} from '../content-fields'
import { OrganizationQueue } from '../organization-models'

export class QueueFields extends ContentFieldsBase {
  constructor(public name = '') {
    super()
  }

  contentType(): NonMessageContentType {
    return ContentType.QUEUE
  }

  static fromHubtype(content: OrganizationQueue): QueueFields {
    const newQueue = new QueueFields()
    newQueue.id = content.id
    newQueue.name = content.name
    return newQueue
  }

  static fromHubtypeCMS(component: HtQueueLocale): QueueFields {
    const newQueue = new QueueFields()
    newQueue.id = component.id
    newQueue.name = component.name
    return newQueue
  }

  toHubtypeCMS(locale: string): HtQueueLocale | undefined {
    if (!this.id || !this.name) return undefined
    const node: HtQueueLocale = {
      id: this.id,
      name: this.name,
      locale: locale,
    }
    return node
  }

  static getCopy(content: QueueFields): QueueFields {
    const newQueue = new QueueFields()
    Object.assign(newQueue, content)
    return newQueue
  }
}
