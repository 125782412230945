import { CMSReader } from '../../domain/models/cms'
import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import { HtNodeComponent } from '../../domain/models/cms/hubtype/nodes'
import { PayloadFields, UrlFields } from '../../domain/models/content-fields'
import { LocaleCode } from '../../domain/models/locales/code'
import { Locale } from '../../domain/models/locales/locale'
import { NodeAction } from '../../UI/reducer/actions/node-actions/node-action'
import {
  FlowContent,
  NonMessageContents,
  OrganizationContents,
} from '../../UI/types'
import { FlowBuilderService } from '../hubtype/flow-builder-service'
import { FlowFactory } from './flow-factory'
import { NodeFactory } from './hubtype-node-factory'

export class HubtypeReader implements CMSReader {
  private nodeFactory
  constructor() {
    this.nodeFactory = new NodeFactory()
  }

  async readFlowContent(
    authToken: string,
    locale: Locale | undefined,
    organizationContents: OrganizationContents,
    version: string
  ): Promise<FlowContent | undefined> {
    const data = await FlowBuilderService.loadFlow(authToken, version)
    if (!data) return undefined
    const nonMessageContents = this.getNonMessageContents(data.nodes)
    const nodes = this.nodeFactory.getNodes(
      [...data.nodes, ...(data.webview_contents || [])],
      locale?.code || (data.locales[0] as LocaleCode),
      data.locales as LocaleCode[],
      organizationContents,
      nonMessageContents
    )

    const startNode = NodeAction.createStartNode()
    const flows = new FlowFactory(data, startNode).getFlows()
    startNode.data.setErrors()
    nodes.push(startNode)

    return {
      nodes,
      nonMessageContents,
      aiModelId: data.ai_model_id,
      isKnowledgeBaseActive: data.is_knowledge_base_active,
      locales: data.locales,
      botVariables: data.bot_variables,
      flows,
      hash: data.hash,
    }
  }

  private getNonMessageContents(nodes: HtNodeComponent[]): NonMessageContents {
    const nonMessageContents: NonMessageContents = {
      urls: [],
      payloads: [],
    }
    nodes.forEach(node => {
      if (node.type === HtContentType.URL) {
        nonMessageContents.urls.push(UrlFields.fromHubtypeCMS(node))
      }
    })
    nodes.forEach(node => {
      if (node.type === HtContentType.PAYLOAD) {
        nonMessageContents.payloads.push(PayloadFields.fromHubtypeCMS(node))
      }
    })
    return nonMessageContents
  }
}
