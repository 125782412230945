import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { AriaListBoxOptions, useListBox, useListBoxSection } from 'react-aria'
import { SelectState } from 'react-stately'

import { normalizeValue } from '../../../../../utils/string-utils'
import { Option } from './option'
import {
  SectionTitle,
  StyledNoMatchesText,
  StyledSection,
  StyledSelectList,
} from './select-styles'

interface ListBoxProps<T extends object> extends AriaListBoxOptions<T> {
  state: SelectState<T>
  filterValue?: string
}

interface ListBoxSectionProps<T> {
  key: React.Key
  state: SelectState<T>
  section: Node<T>
  filterValue?: string
}

export const ListBox = <T extends object>({
  state,
  filterValue,
  ...props
}: ListBoxProps<T>): JSX.Element => {
  const ref = useRef<HTMLUListElement>(null)
  const { listBoxProps } = useListBox(props, state, ref)

  const shouldFilterOption = (item: Node<T>, filterValue?: string): boolean => {
    return Boolean(
      filterValue &&
        item.textValue &&
        !normalizeValue(item.textValue).includes(normalizeValue(filterValue))
    )
  }

  const getItems = () => {
    return [...state.collection].filter(
      item => !shouldFilterOption(item, filterValue)
    )
  }

  const items = getItems()

  const renderListItem = (item: Node<T>) => {
    const commonProps = { key: item.key, item, state }
    if (item.type === 'section') {
      return <HtListBoxSection section={item} {...commonProps} />
    }
    return <Option {...commonProps} />
  }

  return (
    <StyledSelectList {...listBoxProps} ref={ref}>
      {items.length > 0 ? (
        items.map(item => renderListItem(item))
      ) : (
        <StyledNoMatchesText>No matches found</StyledNoMatchesText>
      )}
    </StyledSelectList>
  )
}

const HtListBoxSection = <T extends object>({
  section,
  state,
}: ListBoxSectionProps<T>): JSX.Element => {
  const { itemProps, headingProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  return (
    <StyledSection {...itemProps}>
      {section.rendered && (
        <SectionTitle {...headingProps}>{section.rendered}</SectionTitle>
      )}
      {[...section.childNodes].map(node => (
        <Option key={node.key} item={node} state={state} />
      ))}
    </StyledSection>
  )
}
