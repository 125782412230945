import { FeedbackType } from '../../../../domain/feedback'
import { HubtypeUser } from '../../../../domain/models/organization-models'
import { FlowBuilderService } from '../../../../repository/hubtype/flow-builder-service'
import { HubtypeService } from '../../../../repository/hubtype/hubtype-service'
import { useRepository } from '../../../../repository/repository-utils'
import { SaveOrigin } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { LoadingMessage } from '../../../types'
import { useFlowBuilderFeedback } from '../../../use-flow-builder-feedback'
import { useSave } from '../save/use-save'
import { VersionHistoryData } from '../version-history-dropdown/version-history-dropdown'

export const usePublish = () => {
  const repository = useRepository()
  const { saveFlow } = useSave()
  const {
    state,
    setLoadingMessage,
    removeFeedbackMessages,
    setHashPublished,
    setVersionHistoryData,
  } = useFlowBuilderSelector(ctx => ctx)
  const { reportInform, reportFlowPublished } = useFlowBuilderFeedback()

  const publishFlow = async () => {
    setLoadingMessage(LoadingMessage.PUBLISH)
    const isSaved = await saveFlow(SaveOrigin.BEFORE_PUBLISH)
    const hasFlowErrors = state.nodes.some(
      node =>
        node.data.errors.fieldErrors.length ||
        node.data.errors.hasDuplicatedCode
    )
    if (hasFlowErrors) {
      reportInform('Fix errors to publish')
    }
    if (isSaved && !hasFlowErrors) {
      removeFeedbackMessages()
      const publishedStatus = await repository.cmsEnvironment.publishFlow(
        state.authToken
      )
      if (publishedStatus === FeedbackType.SUCCESS) {
        setHashPublished(state.hash)
        getVersionHistoryData()
      }
      reportFlowPublished(publishedStatus)
    }
    setLoadingMessage(undefined)
  }

  const getVersionHistoryData = async (): Promise<void> => {
    const versionData = await FlowBuilderService.getVersionHistory(
      state.authToken
    )
    if (!Array.isArray(versionData)) return
    const dataWithUserInfo = await populateVersionsUserInfo(
      versionData,
      state.authToken
    )
    setVersionHistoryData(dataWithUserInfo)
  }

  return { publishFlow, getVersionHistoryData }
}

const populateVersionsUserInfo = async (
  versionData: VersionHistoryData[],
  authToken: string
): Promise<VersionHistoryData[]> => {
  const uniqueUserIds = [...new Set(versionData.map(user => user.createdBy))]
  const userInfo: Record<string, HubtypeUser | undefined> = {}
  const promises = uniqueUserIds.map(id =>
    HubtypeService.getUserData(authToken, id)
  )
  const usersData = await Promise.all(promises)
  usersData.forEach(data => {
    if (data) userInfo[data.id] = data
  })
  return versionData.map(data => ({
    ...data,
    user: userInfo[data.createdBy],
  }))
}
