import { memo } from 'react'

import { HandoffNodeProps } from '../../types'
import { Icon, TextBodySmall, TextSmallBold } from '../base'
import { Handles, NodeHeader } from './node-components'
import { MessageNode, StyledList } from './node-styles'

const HandoffNode = ({ data, selected }: HandoffNodeProps) => {
  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={!data.payload} contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Agent handoff'} icon={Icon.HEADSET} />
      {data.queue && (
        <StyledList $hasMarginTop>
          <div>
            <TextSmallBold>Queue</TextSmallBold>
            <TextBodySmall>{data.queue.name}</TextBodySmall>
          </div>
          {data.payload && (
            <div>
              <TextSmallBold>Bot action after agent assistance</TextSmallBold>
              <TextBodySmall>{data.payload.name}</TextBodySmall>
            </div>
          )}
        </StyledList>
      )}
    </MessageNode>
  )
}

export default memo(HandoffNode)
