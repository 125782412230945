import { useEffect } from 'react'
import { ReactFlowProvider } from 'reactflow'

import { useRepository } from '../repository/repository-utils'
import { SaveOrigin } from './analytics'
import { FeedbackPanel } from './components/feedback-panel/feedback-panel'
import { Header } from './components/header/header'
import { usePublish } from './components/header/publish/use-publish'
import { useSave } from './components/header/save/use-save'
import { VersionHistoryData } from './components/header/version-history-dropdown/version-history-dropdown'
import { LocalesPanel } from './components/locale-panel/locales-panel'
import { NodeEditorPanel } from './components/node-editor-panel/node-editor-panel'
import { renderPopup } from './components/popup/popup'
import { VersionBanner } from './components/version-banner/version-banner'
import { DRAFT_VERSION, SESSION_RESOLUTION_TIME } from './constants'
import { useLoadFlow } from './custom-hooks/use-load-flow'
import { SessionResolution, usePusher } from './custom-hooks/use-pusher'
import Flow from './flow'
import { FlowContainer } from './flow-styles'
import { useFlowBuilderSelector } from './reducer/hooks'
import { LoadingMessage, PopupType } from './types'
import { useFlowBuilderFeedback } from './use-flow-builder-feedback'

const FlowBuilder = (): JSX.Element => {
  const { reportError } = useFlowBuilderFeedback()
  const {
    state,
    removeFeedbackMessages,
    restoreChangeHistory,
    setLoadingMessage,
    setPopupContent,
    toggleFlowSaved,
    toggleInteractivity,
    setCurrentVersion,
  } = useFlowBuilderSelector(ctx => ctx)

  const authToken = state.authToken

  const { loadInitialFlow, loadFlow } = useLoadFlow()
  const repository = useRepository()
  const { saveFlow } = useSave()
  const { getVersionHistoryData } = usePublish()

  const { sessionState } = usePusher(authToken)

  useEffect(() => {
    const isFlowAlreadyLoaded = state.nodes.length > 0
    if (sessionState.userLoggingIn && sessionState.currentlyActiveUserId) {
      setLoadingMessage(LoadingMessage.LOADING_CONTENT)
      if (sessionState.resolution === SessionResolution.NoConflict) {
        const resolvingSessionTimeout =
          sessionState.resolution !== sessionState.previousResolution
            ? SESSION_RESOLUTION_TIME
            : 0

        setTimeout(() => {
          if (!isFlowAlreadyLoaded) {
            loadInitialFlow(sessionState)
            getVersionHistoryData()
          }
        }, resolvingSessionTimeout)
        return
      }
      if (sessionState.resolution === SessionResolution.Conflict) {
        setPopupContent({
          type: PopupType.SESSION_CONFLICT,
          activeUserId: sessionState.currentlyActiveUserId,
          isAnotherUser: sessionState?.isAnotherUser ?? false,
          onConfirm: () => sessionState.logUserOut && sessionState.logUserOut(),
          onDiscard: () => sessionState.logMeOut && sessionState.logMeOut(),
          closeData: null,
        })
        return
      }

      if (sessionState.resolution === SessionResolution.Ended) {
        const _save = async () => {
          await saveFlow(SaveOrigin.ON_SESSION_ENDED)
        }
        _save()
        setPopupContent({
          type: PopupType.SESSION_ENDED,
          activeUserId: sessionState.currentlyActiveUserId,
          closeData: null,
        })
        return
      }
    }
  }, [sessionState])

  const uploadFile = async (file: File) => {
    const asset = await repository.cmsWriter.uploadFile(authToken, file)
    if (!asset) {
      reportError('Error uploading image')
    }
    return asset
  }

  const loadPublishedVersion = async (
    version: VersionHistoryData
  ): Promise<void> => {
    setLoadingMessage(LoadingMessage.LOADING_VERSION)
    if (!state.isFlowSaved) {
      await saveFlow(SaveOrigin.ON_OPEN_PREVIOUS_VERSION)
    }
    restoreChangeHistory()
    removeFeedbackMessages()
    await loadFlow(state.currentLocale, version.id, state.organizationContents)
    setLoadingMessage(undefined)
    toggleInteractivity(false)
    toggleFlowSaved(true)
    setCurrentVersion(version)
  }

  const restoreDraftFlow = async (): Promise<void> => {
    setCurrentVersion(undefined)
    toggleInteractivity(true)
    setLoadingMessage(LoadingMessage.LOADING_CONTENT)
    await loadFlow(
      state.currentLocale,
      DRAFT_VERSION,
      state.organizationContents
    )
  }

  return (
    <>
      {sessionState.resolution === SessionResolution.NoConflict && (
        <ReactFlowProvider>
          <VersionBanner restoreDraftFlow={restoreDraftFlow} />
          {!state.isReadOnly && !state.loadingMessage && <FeedbackPanel />}
          {state.nodes.length > 0 && (
            <FlowContainer id='flow-container'>
              <Header loadPublishedVersion={loadPublishedVersion} />
              {state.isLocalesPanelOpen && <LocalesPanel />}
              <Flow />
              {state.currentNode && <NodeEditorPanel uploadFile={uploadFile} />}
            </FlowContainer>
          )}
        </ReactFlowProvider>
      )}
      {state.popupContent && renderPopup(state.popupContent)}
    </>
  )
}
export default FlowBuilder
