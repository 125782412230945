import { memo } from 'react'

import { ChannelFields } from '../../../../../domain/models/content-fields'
import { AVAILABLE_CHANNELS } from '../../../../constants'
import { TextBodyBold } from '../../../base'
import { HtInfoPanel } from '../../../base/ht-info-panel/ht-info-panel'
import { CHANNELS } from '../../constants'
import { SortableDropdownWidgetField } from '../../editor-widgets/sortable-dropdown-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'

interface ChannelEditorProps extends EditorProps {
  nodeContent: ChannelFields
}

export const ChannelEditor = memo(
  ({ nodeContent, ...props }: ChannelEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Channel</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <SortableDropdownWidgetField
          {...props}
          nodeContent={nodeContent}
          contentType={nodeContent.contentType()}
          applyButtonText='Add channels'
          field={CHANNELS}
          values={nodeContent.channels}
          availableItems={AVAILABLE_CHANNELS}
        ></SortableDropdownWidgetField>
        <HtInfoPanel
          title='About channel condition'
          text={
            'Channel condition lets the users follow a specific flow depending on the channel they are using. You can differentiate all channels you need and the rest of them will follow the “Any other channels” flow.'
          }
          recommendedUseText='Use this condition when you want to define different actions depending on the channel or, for example, create a different flow through channels that allow components that others may not.'
        ></HtInfoPanel>
      </>
    )
  }
)
