import styled from 'styled-components'

import { COLORS } from '../../colors'
import { StyledList } from '../../common/dropdown/styles'
import { StyledButton } from '../../ht-button/ht-button-styles'
import { textSmall } from '../../typography'

export const StyledListItem = styled.li`
  padding: 8px 16px;
  &:hover {
    background: ${COLORS.N100};
  }
  &:focus {
    outline: 0;
  }
`

export const StyledCheckboxList = styled(StyledList)`
  overflow-y: auto;
  max-height: 280px;

  hr {
    border: none;
    border-top: 1px solid ${COLORS.N200};
    width: 100%;
    margin: 0px;
  }
`

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 0px;

  button {
    ${textSmall};
    color: ${COLORS.L500};
    background: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;
  }
`

export const ListFooter = styled.div`
  ${StyledButton} {
    width: calc(100% - 16px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 4px 8px 0px;
  }
`
