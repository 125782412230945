import { memo } from 'react'

import {
  HandoffFields,
  QueueFields,
} from '../../../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import {
  DropdownData,
  HtInfoPanel,
  HtInput,
  HtMultisectionDropdown,
  MultisectionDropdownData,
  TextBodyBold,
} from '../../../base'
import { Action, AUTO_ASSIGN, HANDOFF_PAYLOAD, QUEUE } from '../../constants'
import { FieldsCheckbox } from '../../editor-widgets/checkbox-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'

interface HandoffEditorProps extends EditorProps {
  nodeContent: HandoffFields
}

export const HandoffEditor = memo(
  ({ nodeContent, ...props }: HandoffEditorProps): JSX.Element => {
    const analytics = useAnalytics()
    const { organizationContents } = useFlowBuilderSelector(ctx => ctx.state)

    const getQueueDropdownData =
      (): MultisectionDropdownData<QueueFields>[] => {
        return organizationContents.projects.map(p => ({
          title: p.name,
          items: p.queues,
        }))
      }

    const onQueueChange = (value?: DropdownData) => {
      const change = { type: QUEUE.actionType, fieldKey: QUEUE.key, value }
      //@ts-ignore
      props.onChange(change)
    }

    const onAutoAssignChanges = (action: Action) => {
      const actionName = nodeContent.hasAutoAssign ? 'disabled' : 'enabled'
      analytics.trackEvent(TrackEventName.AUTOASSIGN, {
        action: actionName,
      })
      props.onChange(action)
    }

    // TODO. remove when we stop supporting old payloads
    const onRemovePayload = () => {
      const change = {
        type: HANDOFF_PAYLOAD.actionType,
        fieldKey: HANDOFF_PAYLOAD.key,
        value: undefined,
      }
      //@ts-ignore
      props.onChange(change)
    }

    return (
      <>
        <TextBodyBold>Agent handoff</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <HtMultisectionDropdown
          {...props}
          label={QUEUE.label!}
          data={getQueueDropdownData()}
          selectedKey={nodeContent.queue?.id}
          onChange={onQueueChange}
          width={'100%'}
        />
        {nodeContent.payload && (
          <HtInput
            label={HANDOFF_PAYLOAD.label}
            value={nodeContent.payload.name}
            readOnly
            onCrossClick={onRemovePayload}
          />
        )}
        <FieldsCheckbox
          {...props}
          field={AUTO_ASSIGN}
          checked={nodeContent.hasAutoAssign}
          onChange={onAutoAssignChanges}
        />
        <HtInfoPanel
          title='About agent handoff'
          text={
            'Once the user has been attended and the agent has closed the case, flow can be set to continue either through a bot action or moving on to the next conversational app component.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
