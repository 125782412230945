import Pusher, { PresenceChannel } from 'pusher-js'
import { useEffect, useState } from 'react'

import { FlowBuilderUser } from '../../domain/models/organization-models'
import { FlowBuilderService } from '../../repository/hubtype/flow-builder-service'
import { HubtypeApi } from '../../repository/hubtype/hubtype-api'

export interface Member {
  id: string
}

export interface OnPusherMemberRemovedArgs {
  memberLoggingIn: Member
  memberLoggingOut: Member
}

export enum PusherEvent {
  SUBSCRIPTION_SUCCEEDED = 'pusher:subscription_succeeded',
  CLIENT_MEMBER_REMOVED = 'client-pusher:member_removed',
  MEMBER_ADDED = 'pusher:member_added',
}

export const triggerLogUserOut = (
  member: Member,
  flowBuilderChannel: PresenceChannel
) => {
  flowBuilderChannel.members.removeMember(member)
  flowBuilderChannel.trigger(PusherEvent.CLIENT_MEMBER_REMOVED, {
    memberLoggingIn: flowBuilderChannel.members.me,
    memberLoggingOut: member,
  })
}

export const logMeOut = (flowBuilderChannel: PresenceChannel) => {
  flowBuilderChannel.pusher.disconnect()
}

const getPusherKey = () => {
  if (!process.env.REACT_APP_PUSHER_KEY) {
    throw new Error('You need to set env var REACT_APP_PUSHER_KEY')
  }
  return process.env.REACT_APP_PUSHER_KEY
}

const PUSHER_AUTH_ENDPOINT = `${HubtypeApi.getHubtypeApiUrl()}/pusher/flow-builder-auth`

const PUSHER_WS_CONNECTION_TIMEOUT = 500
const PING_PONG_INTERVAL_MILISECONDS = 1000 * 1
const PONG_TIMEOUT_MILISECONDS = 30 * 1000

const PUSHER_PRESENCE_CHANNEL_NAME = 'presence-flow-builder-users'

export enum SessionResolution {
  NoConflict = 'no-conflict',
  Ended = 'ended',
  Conflict = 'conflict',
}

export interface SessionState {
  userLoggingIn?: FlowBuilderUser
  currentlyActiveUserId?: string
  logMeOut?: () => void
  logUserOut?: () => void
  resolution: SessionResolution
  previousResolution?: SessionResolution
  isAnotherUser?: boolean
}

const checkIsAnotherUser = (
  currentMemberId: string,
  incomingMemberId: string
): boolean => {
  const [currentUserId] = currentMemberId.split(':')
  const [incomingUserId] = incomingMemberId.split(':')
  return currentUserId !== incomingUserId
}

export function usePusher(authToken: string) {
  const [pusher, setPusher] = useState<Pusher>()

  const [flowBuilderChannel, setFlowBuilderChannel] =
    useState<PresenceChannel>()

  const [sessionState, setSessionState] = useState<SessionState>({
    userLoggingIn: undefined,
    currentlyActiveUserId: undefined,
    resolution: SessionResolution.NoConflict,
    previousResolution: undefined,
  })

  const identifyUser = async (): Promise<void> => {
    const flowBuilderUser = await FlowBuilderService.getUserInfo(authToken)
    flowBuilderUser &&
      setSessionState(prevState => ({
        ...prevState,
        userLoggingIn: flowBuilderUser,
        previousResolution: prevState.resolution,
      }))
  }

  const getPusherChannelName = (flowBuilderUser: FlowBuilderUser) => {
    return `${PUSHER_PRESENCE_CHANNEL_NAME}-${flowBuilderUser.organizationId}-${flowBuilderUser.botId}`
  }

  const initPusher = () => {
    const userLoggingIn = sessionState.userLoggingIn
    // Try pusher better by disabling StrictMode to avoid confusing duplicate events
    if (!pusher && userLoggingIn) {
      try {
        const pusher = new Pusher(getPusherKey(), {
          cluster: 'eu',
          authEndpoint: PUSHER_AUTH_ENDPOINT,
          auth: { headers: { authorization: `Bearer ${authToken}` } },
          activityTimeout: PING_PONG_INTERVAL_MILISECONDS,
          pongTimeout: PONG_TIMEOUT_MILISECONDS,
        })
        setPusher(pusher)

        setTimeout(() => {
          const channel = pusher.subscribe(
            getPusherChannelName(userLoggingIn)
          ) as PresenceChannel
          setFlowBuilderChannel(channel)
          // give enough time to pusher to set the WS connection
        }, PUSHER_WS_CONNECTION_TIMEOUT)
      } catch (e) {
        // @ts-ignore
        throw new Error(e)
      }
    }
  }

  useEffect(() => {
    if (!sessionState.userLoggingIn) {
      identifyUser()
    }
    if (!pusher) {
      initPusher()
    }
  }, [pusher, sessionState.userLoggingIn])

  useEffect(() => {
    if (!flowBuilderChannel) return

    const _onPusherSubscriptionSucceeded = () => {
      if (flowBuilderChannel.members.count > 1) {
        flowBuilderChannel.members.each(function (member: Member) {
          if (member.id !== flowBuilderChannel.members.myID) {
            setSessionState(prevState => ({
              ...prevState,
              previousResolution: prevState.resolution,
              resolution: SessionResolution.Conflict,
              currentlyActiveUserId: member.id,
              isAnotherUser: checkIsAnotherUser(
                member.id,
                flowBuilderChannel.members.myID
              ),
              logMeOut: () => {
                flowBuilderChannel.pusher.disconnect()
                setSessionState(prevState => ({
                  ...prevState,
                  previousResolution: prevState.resolution,
                  currentlyActiveUserId: member.id,
                  resolution: SessionResolution.NoConflict,
                }))
              },
              logUserOut: () => {
                triggerLogUserOut(member, flowBuilderChannel)
                setSessionState(prevState => ({
                  ...prevState,
                  previousResolution: prevState.resolution,
                  currentlyActiveUserId: flowBuilderChannel.members.myID,
                  resolution: SessionResolution.NoConflict,
                }))
              },
            }))
          }
        })
      } else {
        setSessionState(prevState => ({
          ...prevState,
          previousResolution: prevState.resolution,
          resolution: SessionResolution.NoConflict,
          currentlyActiveUserId: flowBuilderChannel.members.me.id,
        }))
      }
    }

    const _onPusherMemberRemoved = ({
      memberLoggingIn,
      memberLoggingOut,
    }: OnPusherMemberRemovedArgs) => {
      const meId = flowBuilderChannel.members.myID
      if (memberLoggingOut.id === meId) {
        flowBuilderChannel.pusher.disconnect()
        setSessionState(prevState => ({
          ...prevState,
          currentlyActiveUserId: memberLoggingIn.id,
          resolution: SessionResolution.Ended,
        }))
      }
    }

    flowBuilderChannel.bind(PusherEvent.SUBSCRIPTION_SUCCEEDED, () =>
      _onPusherSubscriptionSucceeded()
    )

    flowBuilderChannel.bind(
      PusherEvent.CLIENT_MEMBER_REMOVED,
      async ({
        memberLoggingIn,
        memberLoggingOut,
      }: OnPusherMemberRemovedArgs) =>
        _onPusherMemberRemoved({
          memberLoggingIn,
          memberLoggingOut,
        })
    )

    return () => {
      flowBuilderChannel.unbind(PusherEvent.SUBSCRIPTION_SUCCEEDED, () =>
        _onPusherSubscriptionSucceeded()
      )

      flowBuilderChannel.unbind(
        PusherEvent.CLIENT_MEMBER_REMOVED,
        async ({
          memberLoggingIn,
          memberLoggingOut,
        }: OnPusherMemberRemovedArgs) =>
          _onPusherMemberRemoved({
            memberLoggingIn,
            memberLoggingOut,
          })
      )
    }
  }, [flowBuilderChannel, sessionState])

  return {
    sessionState,
  }
}
